import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.components.animation.registerKeyframes
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlin.Unit
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main(): Unit {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.andb.apps.lyrical.pages.IndexPage() }
        ctx.router.register("/game/{game}") { com.andb.apps.lyrical.pages.game.GamePage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.components.layouts.PageLayoutStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.components.layouts.PageLayoutContainerStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.components.widgets.ButtonStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.components.widgets.DividerStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.components.widgets.SegmentedControlItemStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.components.widgets.SliderStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.components.widgets.Heading1Style)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.components.widgets.Heading2Style)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.components.widgets.SubtitleStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.components.widgets.BodyStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.components.widgets.CaptionStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.pages.game.question.AnswerSectionStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.pages.game.question.SuggestionItemStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.pages.home.LogoStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.pages.home.loggedIn.jumpbar.JumpBarTabStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.theme.OutsetShadowStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.theme.OutsetShadowSmallStyle)
        ctx.theme.registerComponentStyle(com.andb.apps.lyrical.theme.InsetShadowStyle)
        ctx.theme.registerComponentVariants(com.andb.apps.lyrical.components.widgets.PrimaryButtonVariant)
        ctx.theme.registerComponentVariants(com.andb.apps.lyrical.components.widgets.AccentButtonVariant)
        ctx.theme.registerComponentVariants(com.andb.apps.lyrical.components.widgets.EnabledVariant)
        ctx.theme.registerComponentVariants(com.andb.apps.lyrical.components.widgets.DisabledVariant)
        ctx.theme.registerComponentVariants(com.andb.apps.lyrical.pages.home.loggedIn.jumpbar.DefaultJumpBarTabVariant)
        ctx.theme.registerComponentVariants(com.andb.apps.lyrical.pages.home.loggedIn.jumpbar.StartGameJumpBarTabVariant)
        ctx.stylesheet.registerKeyframes(com.andb.apps.lyrical.pages.game.loading.ProgressBar1Keyframes)
        ctx.stylesheet.registerKeyframes(com.andb.apps.lyrical.pages.game.loading.ProgressBar2Keyframes)
        com.andb.apps.lyrical.initSilk(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(AppGlobalsLocal provides mapOf("title" to "Lyrical")) {
            com.andb.apps.lyrical.App {
                router.renderActivePage { renderWithDeferred { it() } }
            }
        }
    }
}
