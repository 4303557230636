//@formatter:off
@file:Suppress("unused", "SpellCheckingInspection")

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// THIS FILE IS AUTOGENERATED.
//
// Do not edit this file by hand. Instead, update `phosphor-icons.txt` and run the Gradle
// task "generatePhosphorIcons"
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

package com.andb.apps.lyrical.components.widgets.phosphor

import androidx.compose.runtime.Composable
import com.andb.apps.lyrical.theme.LyricalTheme
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.dom.I

enum class IconStyle {
    THIN,
    LIGHT,
    REGULAR,
    BOLD,
    FILL,
    DUOTONE,
}

fun IconStyle.toClassName(): String = when (this) {
    IconStyle.THIN -> "ph-thin"
    IconStyle.LIGHT -> "ph-light"
    IconStyle.REGULAR -> "ph-regular"
    IconStyle.BOLD -> "ph-bold"
    IconStyle.FILL -> "ph-fill"
    IconStyle.DUOTONE -> "ph-duotone"
}

@Composable
fun PhIcon(
    name: String,
    modifier: Modifier,
    size: CSSNumeric,
    style: IconStyle,
) {
    I(attrs = modifier.fontSize(size).size(size).toAttrs { classes(name, style.toClassName()) })
}

@Composable fun PhAddressBook(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-address-book", modifier, size, style)
@Composable fun PhAirTrafficControl(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-air-traffic-control", modifier, size, style)
@Composable fun PhAirplane(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-airplane", modifier, size, style)
@Composable fun PhAirplaneInFlight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-airplane-in-flight", modifier, size, style)
@Composable fun PhAirplaneLanding(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-airplane-landing", modifier, size, style)
@Composable fun PhAirplaneTakeoff(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-airplane-takeoff", modifier, size, style)
@Composable fun PhAirplaneTilt(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-airplane-tilt", modifier, size, style)
@Composable fun PhAirplay(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-airplay", modifier, size, style)
@Composable fun PhAlarm(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-alarm", modifier, size, style)
@Composable fun PhAlien(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-alien", modifier, size, style)
@Composable fun PhAlignBottom(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-align-bottom", modifier, size, style)
@Composable fun PhAlignBottomSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-align-bottom-simple", modifier, size, style)
@Composable fun PhAlignCenterHorizontal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-align-center-horizontal", modifier, size, style)
@Composable fun PhAlignCenterHorizontalSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-align-center-horizontal-simple", modifier, size, style)
@Composable fun PhAlignCenterVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-align-center-vertical", modifier, size, style)
@Composable fun PhAlignCenterVerticalSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-align-center-vertical-simple", modifier, size, style)
@Composable fun PhAlignLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-align-left", modifier, size, style)
@Composable fun PhAlignLeftSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-align-left-simple", modifier, size, style)
@Composable fun PhAlignRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-align-right", modifier, size, style)
@Composable fun PhAlignRightSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-align-right-simple", modifier, size, style)
@Composable fun PhAlignTop(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-align-top", modifier, size, style)
@Composable fun PhAlignTopSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-align-top-simple", modifier, size, style)
@Composable fun PhAmazonLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-amazon-logo", modifier, size, style)
@Composable fun PhAnchor(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-anchor", modifier, size, style)
@Composable fun PhAnchorSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-anchor-simple", modifier, size, style)
@Composable fun PhAndroidLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-android-logo", modifier, size, style)
@Composable fun PhAngularLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-angular-logo", modifier, size, style)
@Composable fun PhAperture(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-aperture", modifier, size, style)
@Composable fun PhAppStoreLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-app-store-logo", modifier, size, style)
@Composable fun PhAppWindow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-app-window", modifier, size, style)
@Composable fun PhAppleLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-apple-logo", modifier, size, style)
@Composable fun PhApplePodcastsLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-apple-podcasts-logo", modifier, size, style)
@Composable fun PhArchive(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-archive", modifier, size, style)
@Composable fun PhArchiveBox(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-archive-box", modifier, size, style)
@Composable fun PhArchiveTray(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-archive-tray", modifier, size, style)
@Composable fun PhArmchair(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-armchair", modifier, size, style)
@Composable fun PhArrowArcLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-arc-left", modifier, size, style)
@Composable fun PhArrowArcRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-arc-right", modifier, size, style)
@Composable fun PhArrowBendDoubleUpLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-bend-double-up-left", modifier, size, style)
@Composable fun PhArrowBendDoubleUpRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-bend-double-up-right", modifier, size, style)
@Composable fun PhArrowBendDownLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-bend-down-left", modifier, size, style)
@Composable fun PhArrowBendDownRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-bend-down-right", modifier, size, style)
@Composable fun PhArrowBendLeftDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-bend-left-down", modifier, size, style)
@Composable fun PhArrowBendLeftUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-bend-left-up", modifier, size, style)
@Composable fun PhArrowBendRightDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-bend-right-down", modifier, size, style)
@Composable fun PhArrowBendRightUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-bend-right-up", modifier, size, style)
@Composable fun PhArrowBendUpLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-bend-up-left", modifier, size, style)
@Composable fun PhArrowBendUpRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-bend-up-right", modifier, size, style)
@Composable fun PhArrowCircleDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-circle-down", modifier, size, style)
@Composable fun PhArrowCircleDownLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-circle-down-left", modifier, size, style)
@Composable fun PhArrowCircleDownRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-circle-down-right", modifier, size, style)
@Composable fun PhArrowCircleLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-circle-left", modifier, size, style)
@Composable fun PhArrowCircleRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-circle-right", modifier, size, style)
@Composable fun PhArrowCircleUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-circle-up", modifier, size, style)
@Composable fun PhArrowCircleUpLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-circle-up-left", modifier, size, style)
@Composable fun PhArrowCircleUpRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-circle-up-right", modifier, size, style)
@Composable fun PhArrowClockwise(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-clockwise", modifier, size, style)
@Composable fun PhArrowCounterClockwise(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-counter-clockwise", modifier, size, style)
@Composable fun PhArrowDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-down", modifier, size, style)
@Composable fun PhArrowDownLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-down-left", modifier, size, style)
@Composable fun PhArrowDownRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-down-right", modifier, size, style)
@Composable fun PhArrowElbowDownLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-elbow-down-left", modifier, size, style)
@Composable fun PhArrowElbowDownRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-elbow-down-right", modifier, size, style)
@Composable fun PhArrowElbowLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-elbow-left", modifier, size, style)
@Composable fun PhArrowElbowLeftDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-elbow-left-down", modifier, size, style)
@Composable fun PhArrowElbowLeftUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-elbow-left-up", modifier, size, style)
@Composable fun PhArrowElbowRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-elbow-right", modifier, size, style)
@Composable fun PhArrowElbowRightDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-elbow-right-down", modifier, size, style)
@Composable fun PhArrowElbowRightUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-elbow-right-up", modifier, size, style)
@Composable fun PhArrowElbowUpLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-elbow-up-left", modifier, size, style)
@Composable fun PhArrowElbowUpRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-elbow-up-right", modifier, size, style)
@Composable fun PhArrowFatDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-fat-down", modifier, size, style)
@Composable fun PhArrowFatLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-fat-left", modifier, size, style)
@Composable fun PhArrowFatLineDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-fat-line-down", modifier, size, style)
@Composable fun PhArrowFatLineLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-fat-line-left", modifier, size, style)
@Composable fun PhArrowFatLineRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-fat-line-right", modifier, size, style)
@Composable fun PhArrowFatLineUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-fat-line-up", modifier, size, style)
@Composable fun PhArrowFatLinesDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-fat-lines-down", modifier, size, style)
@Composable fun PhArrowFatLinesLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-fat-lines-left", modifier, size, style)
@Composable fun PhArrowFatLinesRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-fat-lines-right", modifier, size, style)
@Composable fun PhArrowFatLinesUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-fat-lines-up", modifier, size, style)
@Composable fun PhArrowFatRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-fat-right", modifier, size, style)
@Composable fun PhArrowFatUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-fat-up", modifier, size, style)
@Composable fun PhArrowLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-left", modifier, size, style)
@Composable fun PhArrowLineDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-line-down", modifier, size, style)
@Composable fun PhArrowLineDownLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-line-down-left", modifier, size, style)
@Composable fun PhArrowLineDownRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-line-down-right", modifier, size, style)
@Composable fun PhArrowLineLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-line-left", modifier, size, style)
@Composable fun PhArrowLineRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-line-right", modifier, size, style)
@Composable fun PhArrowLineUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-line-up", modifier, size, style)
@Composable fun PhArrowLineUpLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-line-up-left", modifier, size, style)
@Composable fun PhArrowLineUpRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-line-up-right", modifier, size, style)
@Composable fun PhArrowRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-right", modifier, size, style)
@Composable fun PhArrowSquareDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-square-down", modifier, size, style)
@Composable fun PhArrowSquareDownLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-square-down-left", modifier, size, style)
@Composable fun PhArrowSquareDownRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-square-down-right", modifier, size, style)
@Composable fun PhArrowSquareIn(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-square-in", modifier, size, style)
@Composable fun PhArrowSquareLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-square-left", modifier, size, style)
@Composable fun PhArrowSquareOut(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-square-out", modifier, size, style)
@Composable fun PhArrowSquareRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-square-right", modifier, size, style)
@Composable fun PhArrowSquareUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-square-up", modifier, size, style)
@Composable fun PhArrowSquareUpLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-square-up-left", modifier, size, style)
@Composable fun PhArrowSquareUpRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-square-up-right", modifier, size, style)
@Composable fun PhArrowUDownLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-u-down-left", modifier, size, style)
@Composable fun PhArrowUDownRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-u-down-right", modifier, size, style)
@Composable fun PhArrowULeftDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-u-left-down", modifier, size, style)
@Composable fun PhArrowULeftUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-u-left-up", modifier, size, style)
@Composable fun PhArrowURightDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-u-right-down", modifier, size, style)
@Composable fun PhArrowURightUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-u-right-up", modifier, size, style)
@Composable fun PhArrowUUpLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-u-up-left", modifier, size, style)
@Composable fun PhArrowUUpRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-u-up-right", modifier, size, style)
@Composable fun PhArrowUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-up", modifier, size, style)
@Composable fun PhArrowUpLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-up-left", modifier, size, style)
@Composable fun PhArrowUpRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrow-up-right", modifier, size, style)
@Composable fun PhArrowsClockwise(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-clockwise", modifier, size, style)
@Composable fun PhArrowsCounterClockwise(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-counter-clockwise", modifier, size, style)
@Composable fun PhArrowsDownUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-down-up", modifier, size, style)
@Composable fun PhArrowsHorizontal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-horizontal", modifier, size, style)
@Composable fun PhArrowsIn(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-in", modifier, size, style)
@Composable fun PhArrowsInCardinal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-in-cardinal", modifier, size, style)
@Composable fun PhArrowsInLineHorizontal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-in-line-horizontal", modifier, size, style)
@Composable fun PhArrowsInLineVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-in-line-vertical", modifier, size, style)
@Composable fun PhArrowsInSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-in-simple", modifier, size, style)
@Composable fun PhArrowsLeftRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-left-right", modifier, size, style)
@Composable fun PhArrowsMerge(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-merge", modifier, size, style)
@Composable fun PhArrowsOut(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-out", modifier, size, style)
@Composable fun PhArrowsOutCardinal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-out-cardinal", modifier, size, style)
@Composable fun PhArrowsOutLineHorizontal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-out-line-horizontal", modifier, size, style)
@Composable fun PhArrowsOutLineVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-out-line-vertical", modifier, size, style)
@Composable fun PhArrowsOutSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-out-simple", modifier, size, style)
@Composable fun PhArrowsSplit(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-split", modifier, size, style)
@Composable fun PhArrowsVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-arrows-vertical", modifier, size, style)
@Composable fun PhArticle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-article", modifier, size, style)
@Composable fun PhArticleMedium(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-article-medium", modifier, size, style)
@Composable fun PhArticleNyTimes(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-article-ny-times", modifier, size, style)
@Composable fun PhAsterisk(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-asterisk", modifier, size, style)
@Composable fun PhAsteriskSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-asterisk-simple", modifier, size, style)
@Composable fun PhAt(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-at", modifier, size, style)
@Composable fun PhAtom(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-atom", modifier, size, style)
@Composable fun PhBaby(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-baby", modifier, size, style)
@Composable fun PhBackpack(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-backpack", modifier, size, style)
@Composable fun PhBackspace(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-backspace", modifier, size, style)
@Composable fun PhBag(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bag", modifier, size, style)
@Composable fun PhBagSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bag-simple", modifier, size, style)
@Composable fun PhBalloon(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-balloon", modifier, size, style)
@Composable fun PhBandaids(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bandaids", modifier, size, style)
@Composable fun PhBank(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bank", modifier, size, style)
@Composable fun PhBarbell(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-barbell", modifier, size, style)
@Composable fun PhBarcode(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-barcode", modifier, size, style)
@Composable fun PhBarricade(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-barricade", modifier, size, style)
@Composable fun PhBaseball(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-baseball", modifier, size, style)
@Composable fun PhBaseballCap(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-baseball-cap", modifier, size, style)
@Composable fun PhBasket(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-basket", modifier, size, style)
@Composable fun PhBasketball(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-basketball", modifier, size, style)
@Composable fun PhBathtub(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bathtub", modifier, size, style)
@Composable fun PhBatteryCharging(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-charging", modifier, size, style)
@Composable fun PhBatteryChargingVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-charging-vertical", modifier, size, style)
@Composable fun PhBatteryEmpty(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-empty", modifier, size, style)
@Composable fun PhBatteryFull(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-full", modifier, size, style)
@Composable fun PhBatteryHigh(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-high", modifier, size, style)
@Composable fun PhBatteryLow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-low", modifier, size, style)
@Composable fun PhBatteryMedium(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-medium", modifier, size, style)
@Composable fun PhBatteryPlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-plus", modifier, size, style)
@Composable fun PhBatteryPlusVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-plus-vertical", modifier, size, style)
@Composable fun PhBatteryVerticalEmpty(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-vertical-empty", modifier, size, style)
@Composable fun PhBatteryVerticalFull(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-vertical-full", modifier, size, style)
@Composable fun PhBatteryVerticalHigh(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-vertical-high", modifier, size, style)
@Composable fun PhBatteryVerticalLow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-vertical-low", modifier, size, style)
@Composable fun PhBatteryVerticalMedium(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-vertical-medium", modifier, size, style)
@Composable fun PhBatteryWarning(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-warning", modifier, size, style)
@Composable fun PhBatteryWarningVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-battery-warning-vertical", modifier, size, style)
@Composable fun PhBed(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bed", modifier, size, style)
@Composable fun PhBeerBottle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-beer-bottle", modifier, size, style)
@Composable fun PhBeerStein(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-beer-stein", modifier, size, style)
@Composable fun PhBehanceLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-behance-logo", modifier, size, style)
@Composable fun PhBell(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bell", modifier, size, style)
@Composable fun PhBellRinging(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bell-ringing", modifier, size, style)
@Composable fun PhBellSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bell-simple", modifier, size, style)
@Composable fun PhBellSimpleRinging(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bell-simple-ringing", modifier, size, style)
@Composable fun PhBellSimpleSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bell-simple-slash", modifier, size, style)
@Composable fun PhBellSimpleZ(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bell-simple-z", modifier, size, style)
@Composable fun PhBellSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bell-slash", modifier, size, style)
@Composable fun PhBellZ(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bell-z", modifier, size, style)
@Composable fun PhBezierCurve(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bezier-curve", modifier, size, style)
@Composable fun PhBicycle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bicycle", modifier, size, style)
@Composable fun PhBinoculars(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-binoculars", modifier, size, style)
@Composable fun PhBird(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bird", modifier, size, style)
@Composable fun PhBluetooth(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bluetooth", modifier, size, style)
@Composable fun PhBluetoothConnected(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bluetooth-connected", modifier, size, style)
@Composable fun PhBluetoothSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bluetooth-slash", modifier, size, style)
@Composable fun PhBluetoothX(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bluetooth-x", modifier, size, style)
@Composable fun PhBoat(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-boat", modifier, size, style)
@Composable fun PhBone(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bone", modifier, size, style)
@Composable fun PhBook(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-book", modifier, size, style)
@Composable fun PhBookBookmark(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-book-bookmark", modifier, size, style)
@Composable fun PhBookOpen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-book-open", modifier, size, style)
@Composable fun PhBookOpenText(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-book-open-text", modifier, size, style)
@Composable fun PhBookmark(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bookmark", modifier, size, style)
@Composable fun PhBookmarkSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bookmark-simple", modifier, size, style)
@Composable fun PhBookmarks(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bookmarks", modifier, size, style)
@Composable fun PhBookmarksSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bookmarks-simple", modifier, size, style)
@Composable fun PhBooks(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-books", modifier, size, style)
@Composable fun PhBoot(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-boot", modifier, size, style)
@Composable fun PhBoundingBox(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bounding-box", modifier, size, style)
@Composable fun PhBowlFood(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bowl-food", modifier, size, style)
@Composable fun PhBracketsAngle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-brackets-angle", modifier, size, style)
@Composable fun PhBracketsCurly(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-brackets-curly", modifier, size, style)
@Composable fun PhBracketsRound(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-brackets-round", modifier, size, style)
@Composable fun PhBracketsSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-brackets-square", modifier, size, style)
@Composable fun PhBrain(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-brain", modifier, size, style)
@Composable fun PhBrandy(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-brandy", modifier, size, style)
@Composable fun PhBridge(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bridge", modifier, size, style)
@Composable fun PhBriefcase(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-briefcase", modifier, size, style)
@Composable fun PhBriefcaseMetal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-briefcase-metal", modifier, size, style)
@Composable fun PhBroadcast(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-broadcast", modifier, size, style)
@Composable fun PhBroom(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-broom", modifier, size, style)
@Composable fun PhBrowser(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-browser", modifier, size, style)
@Composable fun PhBrowsers(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-browsers", modifier, size, style)
@Composable fun PhBug(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bug", modifier, size, style)
@Composable fun PhBugBeetle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bug-beetle", modifier, size, style)
@Composable fun PhBugDroid(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bug-droid", modifier, size, style)
@Composable fun PhBuildings(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-buildings", modifier, size, style)
@Composable fun PhBus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-bus", modifier, size, style)
@Composable fun PhButterfly(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-butterfly", modifier, size, style)
@Composable fun PhCactus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cactus", modifier, size, style)
@Composable fun PhCake(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cake", modifier, size, style)
@Composable fun PhCalculator(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-calculator", modifier, size, style)
@Composable fun PhCalendar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-calendar", modifier, size, style)
@Composable fun PhCalendarBlank(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-calendar-blank", modifier, size, style)
@Composable fun PhCalendarCheck(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-calendar-check", modifier, size, style)
@Composable fun PhCalendarPlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-calendar-plus", modifier, size, style)
@Composable fun PhCalendarX(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-calendar-x", modifier, size, style)
@Composable fun PhCallBell(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-call-bell", modifier, size, style)
@Composable fun PhCamera(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-camera", modifier, size, style)
@Composable fun PhCameraPlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-camera-plus", modifier, size, style)
@Composable fun PhCameraRotate(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-camera-rotate", modifier, size, style)
@Composable fun PhCameraSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-camera-slash", modifier, size, style)
@Composable fun PhCampfire(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-campfire", modifier, size, style)
@Composable fun PhCar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-car", modifier, size, style)
@Composable fun PhCarProfile(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-car-profile", modifier, size, style)
@Composable fun PhCarSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-car-simple", modifier, size, style)
@Composable fun PhCardholder(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cardholder", modifier, size, style)
@Composable fun PhCards(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cards", modifier, size, style)
@Composable fun PhCaretCircleDoubleDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-circle-double-down", modifier, size, style)
@Composable fun PhCaretCircleDoubleLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-circle-double-left", modifier, size, style)
@Composable fun PhCaretCircleDoubleRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-circle-double-right", modifier, size, style)
@Composable fun PhCaretCircleDoubleUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-circle-double-up", modifier, size, style)
@Composable fun PhCaretCircleDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-circle-down", modifier, size, style)
@Composable fun PhCaretCircleLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-circle-left", modifier, size, style)
@Composable fun PhCaretCircleRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-circle-right", modifier, size, style)
@Composable fun PhCaretCircleUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-circle-up", modifier, size, style)
@Composable fun PhCaretCircleUpDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-circle-up-down", modifier, size, style)
@Composable fun PhCaretDoubleDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-double-down", modifier, size, style)
@Composable fun PhCaretDoubleLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-double-left", modifier, size, style)
@Composable fun PhCaretDoubleRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-double-right", modifier, size, style)
@Composable fun PhCaretDoubleUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-double-up", modifier, size, style)
@Composable fun PhCaretDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-down", modifier, size, style)
@Composable fun PhCaretLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-left", modifier, size, style)
@Composable fun PhCaretRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-right", modifier, size, style)
@Composable fun PhCaretUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-up", modifier, size, style)
@Composable fun PhCaretUpDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-caret-up-down", modifier, size, style)
@Composable fun PhCarrot(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-carrot", modifier, size, style)
@Composable fun PhCassetteTape(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cassette-tape", modifier, size, style)
@Composable fun PhCastleTurret(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-castle-turret", modifier, size, style)
@Composable fun PhCat(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cat", modifier, size, style)
@Composable fun PhCellSignalFull(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cell-signal-full", modifier, size, style)
@Composable fun PhCellSignalHigh(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cell-signal-high", modifier, size, style)
@Composable fun PhCellSignalLow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cell-signal-low", modifier, size, style)
@Composable fun PhCellSignalMedium(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cell-signal-medium", modifier, size, style)
@Composable fun PhCellSignalNone(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cell-signal-none", modifier, size, style)
@Composable fun PhCellSignalSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cell-signal-slash", modifier, size, style)
@Composable fun PhCellSignalX(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cell-signal-x", modifier, size, style)
@Composable fun PhCertificate(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-certificate", modifier, size, style)
@Composable fun PhChair(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chair", modifier, size, style)
@Composable fun PhChalkboard(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chalkboard", modifier, size, style)
@Composable fun PhChalkboardSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chalkboard-simple", modifier, size, style)
@Composable fun PhChalkboardTeacher(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chalkboard-teacher", modifier, size, style)
@Composable fun PhChampagne(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-champagne", modifier, size, style)
@Composable fun PhChargingStation(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-charging-station", modifier, size, style)
@Composable fun PhChartBar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chart-bar", modifier, size, style)
@Composable fun PhChartBarHorizontal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chart-bar-horizontal", modifier, size, style)
@Composable fun PhChartDonut(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chart-donut", modifier, size, style)
@Composable fun PhChartLine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chart-line", modifier, size, style)
@Composable fun PhChartLineDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chart-line-down", modifier, size, style)
@Composable fun PhChartLineUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chart-line-up", modifier, size, style)
@Composable fun PhChartPie(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chart-pie", modifier, size, style)
@Composable fun PhChartPieSlice(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chart-pie-slice", modifier, size, style)
@Composable fun PhChartPolar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chart-polar", modifier, size, style)
@Composable fun PhChartScatter(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chart-scatter", modifier, size, style)
@Composable fun PhChat(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chat", modifier, size, style)
@Composable fun PhChatCentered(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chat-centered", modifier, size, style)
@Composable fun PhChatCenteredDots(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chat-centered-dots", modifier, size, style)
@Composable fun PhChatCenteredText(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chat-centered-text", modifier, size, style)
@Composable fun PhChatCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chat-circle", modifier, size, style)
@Composable fun PhChatCircleDots(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chat-circle-dots", modifier, size, style)
@Composable fun PhChatCircleText(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chat-circle-text", modifier, size, style)
@Composable fun PhChatDots(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chat-dots", modifier, size, style)
@Composable fun PhChatTeardrop(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chat-teardrop", modifier, size, style)
@Composable fun PhChatTeardropDots(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chat-teardrop-dots", modifier, size, style)
@Composable fun PhChatTeardropText(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chat-teardrop-text", modifier, size, style)
@Composable fun PhChatText(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chat-text", modifier, size, style)
@Composable fun PhChats(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chats", modifier, size, style)
@Composable fun PhChatsCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chats-circle", modifier, size, style)
@Composable fun PhChatsTeardrop(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-chats-teardrop", modifier, size, style)
@Composable fun PhCheck(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-check", modifier, size, style)
@Composable fun PhCheckCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-check-circle", modifier, size, style)
@Composable fun PhCheckFat(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-check-fat", modifier, size, style)
@Composable fun PhCheckSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-check-square", modifier, size, style)
@Composable fun PhCheckSquareOffset(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-check-square-offset", modifier, size, style)
@Composable fun PhChecks(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-checks", modifier, size, style)
@Composable fun PhChurch(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-church", modifier, size, style)
@Composable fun PhCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-circle", modifier, size, style)
@Composable fun PhCircleDashed(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-circle-dashed", modifier, size, style)
@Composable fun PhCircleHalf(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-circle-half", modifier, size, style)
@Composable fun PhCircleHalfTilt(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-circle-half-tilt", modifier, size, style)
@Composable fun PhCircleNotch(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-circle-notch", modifier, size, style)
@Composable fun PhCirclesFour(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-circles-four", modifier, size, style)
@Composable fun PhCirclesThree(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-circles-three", modifier, size, style)
@Composable fun PhCirclesThreePlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-circles-three-plus", modifier, size, style)
@Composable fun PhCircuitry(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-circuitry", modifier, size, style)
@Composable fun PhClipboard(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-clipboard", modifier, size, style)
@Composable fun PhClipboardText(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-clipboard-text", modifier, size, style)
@Composable fun PhClock(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-clock", modifier, size, style)
@Composable fun PhClockAfternoon(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-clock-afternoon", modifier, size, style)
@Composable fun PhClockClockwise(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-clock-clockwise", modifier, size, style)
@Composable fun PhClockCountdown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-clock-countdown", modifier, size, style)
@Composable fun PhClockCounterClockwise(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-clock-counter-clockwise", modifier, size, style)
@Composable fun PhClosedCaptioning(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-closed-captioning", modifier, size, style)
@Composable fun PhCloud(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cloud", modifier, size, style)
@Composable fun PhCloudArrowDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cloud-arrow-down", modifier, size, style)
@Composable fun PhCloudArrowUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cloud-arrow-up", modifier, size, style)
@Composable fun PhCloudCheck(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cloud-check", modifier, size, style)
@Composable fun PhCloudFog(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cloud-fog", modifier, size, style)
@Composable fun PhCloudLightning(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cloud-lightning", modifier, size, style)
@Composable fun PhCloudMoon(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cloud-moon", modifier, size, style)
@Composable fun PhCloudRain(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cloud-rain", modifier, size, style)
@Composable fun PhCloudSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cloud-slash", modifier, size, style)
@Composable fun PhCloudSnow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cloud-snow", modifier, size, style)
@Composable fun PhCloudSun(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cloud-sun", modifier, size, style)
@Composable fun PhCloudWarning(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cloud-warning", modifier, size, style)
@Composable fun PhCloudX(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cloud-x", modifier, size, style)
@Composable fun PhClub(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-club", modifier, size, style)
@Composable fun PhCoatHanger(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-coat-hanger", modifier, size, style)
@Composable fun PhCodaLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-coda-logo", modifier, size, style)
@Composable fun PhCode(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-code", modifier, size, style)
@Composable fun PhCodeBlock(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-code-block", modifier, size, style)
@Composable fun PhCodeSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-code-simple", modifier, size, style)
@Composable fun PhCodepenLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-codepen-logo", modifier, size, style)
@Composable fun PhCodesandboxLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-codesandbox-logo", modifier, size, style)
@Composable fun PhCoffee(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-coffee", modifier, size, style)
@Composable fun PhCoin(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-coin", modifier, size, style)
@Composable fun PhCoinVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-coin-vertical", modifier, size, style)
@Composable fun PhCoins(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-coins", modifier, size, style)
@Composable fun PhColumns(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-columns", modifier, size, style)
@Composable fun PhCommand(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-command", modifier, size, style)
@Composable fun PhCompass(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-compass", modifier, size, style)
@Composable fun PhCompassTool(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-compass-tool", modifier, size, style)
@Composable fun PhComputerTower(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-computer-tower", modifier, size, style)
@Composable fun PhConfetti(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-confetti", modifier, size, style)
@Composable fun PhContactlessPayment(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-contactless-payment", modifier, size, style)
@Composable fun PhControl(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-control", modifier, size, style)
@Composable fun PhCookie(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cookie", modifier, size, style)
@Composable fun PhCookingPot(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cooking-pot", modifier, size, style)
@Composable fun PhCopy(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-copy", modifier, size, style)
@Composable fun PhCopySimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-copy-simple", modifier, size, style)
@Composable fun PhCopyleft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-copyleft", modifier, size, style)
@Composable fun PhCopyright(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-copyright", modifier, size, style)
@Composable fun PhCornersIn(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-corners-in", modifier, size, style)
@Composable fun PhCornersOut(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-corners-out", modifier, size, style)
@Composable fun PhCouch(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-couch", modifier, size, style)
@Composable fun PhCpu(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cpu", modifier, size, style)
@Composable fun PhCreditCard(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-credit-card", modifier, size, style)
@Composable fun PhCrop(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-crop", modifier, size, style)
@Composable fun PhCross(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cross", modifier, size, style)
@Composable fun PhCrosshair(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-crosshair", modifier, size, style)
@Composable fun PhCrosshairSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-crosshair-simple", modifier, size, style)
@Composable fun PhCrown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-crown", modifier, size, style)
@Composable fun PhCrownSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-crown-simple", modifier, size, style)
@Composable fun PhCube(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cube", modifier, size, style)
@Composable fun PhCubeFocus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cube-focus", modifier, size, style)
@Composable fun PhCubeTransparent(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cube-transparent", modifier, size, style)
@Composable fun PhCurrencyBtc(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-btc", modifier, size, style)
@Composable fun PhCurrencyCircleDollar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-circle-dollar", modifier, size, style)
@Composable fun PhCurrencyCny(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-cny", modifier, size, style)
@Composable fun PhCurrencyDollar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-dollar", modifier, size, style)
@Composable fun PhCurrencyDollarSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-dollar-simple", modifier, size, style)
@Composable fun PhCurrencyEth(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-eth", modifier, size, style)
@Composable fun PhCurrencyEur(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-eur", modifier, size, style)
@Composable fun PhCurrencyGbp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-gbp", modifier, size, style)
@Composable fun PhCurrencyInr(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-inr", modifier, size, style)
@Composable fun PhCurrencyJpy(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-jpy", modifier, size, style)
@Composable fun PhCurrencyKrw(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-krw", modifier, size, style)
@Composable fun PhCurrencyKzt(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-kzt", modifier, size, style)
@Composable fun PhCurrencyNgn(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-ngn", modifier, size, style)
@Composable fun PhCurrencyRub(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-currency-rub", modifier, size, style)
@Composable fun PhCursor(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cursor", modifier, size, style)
@Composable fun PhCursorClick(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cursor-click", modifier, size, style)
@Composable fun PhCursorText(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cursor-text", modifier, size, style)
@Composable fun PhCylinder(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-cylinder", modifier, size, style)
@Composable fun PhDatabase(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-database", modifier, size, style)
@Composable fun PhDesktop(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-desktop", modifier, size, style)
@Composable fun PhDesktopTower(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-desktop-tower", modifier, size, style)
@Composable fun PhDetective(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-detective", modifier, size, style)
@Composable fun PhDevToLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dev-to-logo", modifier, size, style)
@Composable fun PhDeviceMobile(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-device-mobile", modifier, size, style)
@Composable fun PhDeviceMobileCamera(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-device-mobile-camera", modifier, size, style)
@Composable fun PhDeviceMobileSpeaker(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-device-mobile-speaker", modifier, size, style)
@Composable fun PhDeviceTablet(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-device-tablet", modifier, size, style)
@Composable fun PhDeviceTabletCamera(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-device-tablet-camera", modifier, size, style)
@Composable fun PhDeviceTabletSpeaker(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-device-tablet-speaker", modifier, size, style)
@Composable fun PhDevices(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-devices", modifier, size, style)
@Composable fun PhDiamond(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-diamond", modifier, size, style)
@Composable fun PhDiamondsFour(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-diamonds-four", modifier, size, style)
@Composable fun PhDiceFive(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dice-five", modifier, size, style)
@Composable fun PhDiceFour(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dice-four", modifier, size, style)
@Composable fun PhDiceOne(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dice-one", modifier, size, style)
@Composable fun PhDiceSix(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dice-six", modifier, size, style)
@Composable fun PhDiceThree(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dice-three", modifier, size, style)
@Composable fun PhDiceTwo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dice-two", modifier, size, style)
@Composable fun PhDisc(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-disc", modifier, size, style)
@Composable fun PhDiscordLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-discord-logo", modifier, size, style)
@Composable fun PhDivide(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-divide", modifier, size, style)
@Composable fun PhDna(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dna", modifier, size, style)
@Composable fun PhDog(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dog", modifier, size, style)
@Composable fun PhDoor(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-door", modifier, size, style)
@Composable fun PhDoorOpen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-door-open", modifier, size, style)
@Composable fun PhDot(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dot", modifier, size, style)
@Composable fun PhDotOutline(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dot-outline", modifier, size, style)
@Composable fun PhDotsNine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dots-nine", modifier, size, style)
@Composable fun PhDotsSix(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dots-six", modifier, size, style)
@Composable fun PhDotsSixVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dots-six-vertical", modifier, size, style)
@Composable fun PhDotsThree(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dots-three", modifier, size, style)
@Composable fun PhDotsThreeCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dots-three-circle", modifier, size, style)
@Composable fun PhDotsThreeCircleVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dots-three-circle-vertical", modifier, size, style)
@Composable fun PhDotsThreeOutline(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dots-three-outline", modifier, size, style)
@Composable fun PhDotsThreeOutlineVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dots-three-outline-vertical", modifier, size, style)
@Composable fun PhDotsThreeVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dots-three-vertical", modifier, size, style)
@Composable fun PhDownload(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-download", modifier, size, style)
@Composable fun PhDownloadSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-download-simple", modifier, size, style)
@Composable fun PhDress(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dress", modifier, size, style)
@Composable fun PhDribbbleLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dribbble-logo", modifier, size, style)
@Composable fun PhDrop(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-drop", modifier, size, style)
@Composable fun PhDropHalf(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-drop-half", modifier, size, style)
@Composable fun PhDropHalfBottom(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-drop-half-bottom", modifier, size, style)
@Composable fun PhDropboxLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-dropbox-logo", modifier, size, style)
@Composable fun PhEar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-ear", modifier, size, style)
@Composable fun PhEarSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-ear-slash", modifier, size, style)
@Composable fun PhEgg(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-egg", modifier, size, style)
@Composable fun PhEggCrack(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-egg-crack", modifier, size, style)
@Composable fun PhEject(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-eject", modifier, size, style)
@Composable fun PhEjectSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-eject-simple", modifier, size, style)
@Composable fun PhElevator(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-elevator", modifier, size, style)
@Composable fun PhEngine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-engine", modifier, size, style)
@Composable fun PhEnvelope(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-envelope", modifier, size, style)
@Composable fun PhEnvelopeOpen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-envelope-open", modifier, size, style)
@Composable fun PhEnvelopeSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-envelope-simple", modifier, size, style)
@Composable fun PhEnvelopeSimpleOpen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-envelope-simple-open", modifier, size, style)
@Composable fun PhEqualizer(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-equalizer", modifier, size, style)
@Composable fun PhEquals(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-equals", modifier, size, style)
@Composable fun PhEraser(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-eraser", modifier, size, style)
@Composable fun PhEscalatorDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-escalator-down", modifier, size, style)
@Composable fun PhEscalatorUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-escalator-up", modifier, size, style)
@Composable fun PhExam(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-exam", modifier, size, style)
@Composable fun PhExclude(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-exclude", modifier, size, style)
@Composable fun PhExcludeSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-exclude-square", modifier, size, style)
@Composable fun PhExport(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-export", modifier, size, style)
@Composable fun PhEye(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-eye", modifier, size, style)
@Composable fun PhEyeClosed(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-eye-closed", modifier, size, style)
@Composable fun PhEyeSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-eye-slash", modifier, size, style)
@Composable fun PhEyedropper(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-eyedropper", modifier, size, style)
@Composable fun PhEyedropperSample(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-eyedropper-sample", modifier, size, style)
@Composable fun PhEyeglasses(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-eyeglasses", modifier, size, style)
@Composable fun PhFaceMask(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-face-mask", modifier, size, style)
@Composable fun PhFacebookLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-facebook-logo", modifier, size, style)
@Composable fun PhFactory(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-factory", modifier, size, style)
@Composable fun PhFaders(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-faders", modifier, size, style)
@Composable fun PhFadersHorizontal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-faders-horizontal", modifier, size, style)
@Composable fun PhFan(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-fan", modifier, size, style)
@Composable fun PhFastForward(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-fast-forward", modifier, size, style)
@Composable fun PhFastForwardCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-fast-forward-circle", modifier, size, style)
@Composable fun PhFeather(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-feather", modifier, size, style)
@Composable fun PhFigmaLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-figma-logo", modifier, size, style)
@Composable fun PhFile(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file", modifier, size, style)
@Composable fun PhFileArchive(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-archive", modifier, size, style)
@Composable fun PhFileArrowDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-arrow-down", modifier, size, style)
@Composable fun PhFileArrowUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-arrow-up", modifier, size, style)
@Composable fun PhFileAudio(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-audio", modifier, size, style)
@Composable fun PhFileCloud(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-cloud", modifier, size, style)
@Composable fun PhFileCode(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-code", modifier, size, style)
@Composable fun PhFileCss(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-css", modifier, size, style)
@Composable fun PhFileCsv(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-csv", modifier, size, style)
@Composable fun PhFileDashed(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-dashed", modifier, size, style)
@Composable fun PhFileDotted(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-dotted", modifier, size, style)
@Composable fun PhFileDoc(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-doc", modifier, size, style)
@Composable fun PhFileHtml(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-html", modifier, size, style)
@Composable fun PhFileImage(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-image", modifier, size, style)
@Composable fun PhFileJpg(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-jpg", modifier, size, style)
@Composable fun PhFileJs(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-js", modifier, size, style)
@Composable fun PhFileJsx(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-jsx", modifier, size, style)
@Composable fun PhFileLock(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-lock", modifier, size, style)
@Composable fun PhFileMinus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-minus", modifier, size, style)
@Composable fun PhFilePdf(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-pdf", modifier, size, style)
@Composable fun PhFilePlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-plus", modifier, size, style)
@Composable fun PhFilePng(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-png", modifier, size, style)
@Composable fun PhFilePpt(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-ppt", modifier, size, style)
@Composable fun PhFileRs(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-rs", modifier, size, style)
@Composable fun PhFileSearch(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-search", modifier, size, style)
@Composable fun PhFileSql(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-sql", modifier, size, style)
@Composable fun PhFileSvg(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-svg", modifier, size, style)
@Composable fun PhFileText(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-text", modifier, size, style)
@Composable fun PhFileTs(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-ts", modifier, size, style)
@Composable fun PhFileTsx(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-tsx", modifier, size, style)
@Composable fun PhFileVideo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-video", modifier, size, style)
@Composable fun PhFileVue(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-vue", modifier, size, style)
@Composable fun PhFileX(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-x", modifier, size, style)
@Composable fun PhFileXls(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-xls", modifier, size, style)
@Composable fun PhFileZip(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-file-zip", modifier, size, style)
@Composable fun PhFiles(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-files", modifier, size, style)
@Composable fun PhFilmReel(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-film-reel", modifier, size, style)
@Composable fun PhFilmScript(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-film-script", modifier, size, style)
@Composable fun PhFilmSlate(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-film-slate", modifier, size, style)
@Composable fun PhFilmStrip(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-film-strip", modifier, size, style)
@Composable fun PhFingerprint(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-fingerprint", modifier, size, style)
@Composable fun PhFingerprintSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-fingerprint-simple", modifier, size, style)
@Composable fun PhFinnTheHuman(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-finn-the-human", modifier, size, style)
@Composable fun PhFire(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-fire", modifier, size, style)
@Composable fun PhFireExtinguisher(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-fire-extinguisher", modifier, size, style)
@Composable fun PhFireSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-fire-simple", modifier, size, style)
@Composable fun PhFirstAid(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-first-aid", modifier, size, style)
@Composable fun PhFirstAidKit(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-first-aid-kit", modifier, size, style)
@Composable fun PhFish(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-fish", modifier, size, style)
@Composable fun PhFishSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-fish-simple", modifier, size, style)
@Composable fun PhFlag(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-flag", modifier, size, style)
@Composable fun PhFlagBanner(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-flag-banner", modifier, size, style)
@Composable fun PhFlagCheckered(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-flag-checkered", modifier, size, style)
@Composable fun PhFlagPennant(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-flag-pennant", modifier, size, style)
@Composable fun PhFlame(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-flame", modifier, size, style)
@Composable fun PhFlashlight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-flashlight", modifier, size, style)
@Composable fun PhFlask(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-flask", modifier, size, style)
@Composable fun PhFloppyDisk(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-floppy-disk", modifier, size, style)
@Composable fun PhFloppyDiskBack(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-floppy-disk-back", modifier, size, style)
@Composable fun PhFlowArrow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-flow-arrow", modifier, size, style)
@Composable fun PhFlower(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-flower", modifier, size, style)
@Composable fun PhFlowerLotus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-flower-lotus", modifier, size, style)
@Composable fun PhFlowerTulip(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-flower-tulip", modifier, size, style)
@Composable fun PhFlyingSaucer(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-flying-saucer", modifier, size, style)
@Composable fun PhFolder(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder", modifier, size, style)
@Composable fun PhFolderDashed(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-dashed", modifier, size, style)
@Composable fun PhFolderDotted(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-dotted", modifier, size, style)
@Composable fun PhFolderLock(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-lock", modifier, size, style)
@Composable fun PhFolderMinus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-minus", modifier, size, style)
@Composable fun PhFolderNotch(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-notch", modifier, size, style)
@Composable fun PhFolderNotchMinus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-notch-minus", modifier, size, style)
@Composable fun PhFolderNotchOpen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-notch-open", modifier, size, style)
@Composable fun PhFolderNotchPlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-notch-plus", modifier, size, style)
@Composable fun PhFolderOpen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-open", modifier, size, style)
@Composable fun PhFolderPlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-plus", modifier, size, style)
@Composable fun PhFolderSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-simple", modifier, size, style)
@Composable fun PhFolderSimpleDashed(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-simple-dashed", modifier, size, style)
@Composable fun PhFolderSimpleDotted(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-simple-dotted", modifier, size, style)
@Composable fun PhFolderSimpleLock(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-simple-lock", modifier, size, style)
@Composable fun PhFolderSimpleMinus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-simple-minus", modifier, size, style)
@Composable fun PhFolderSimplePlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-simple-plus", modifier, size, style)
@Composable fun PhFolderSimpleStar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-simple-star", modifier, size, style)
@Composable fun PhFolderSimpleUser(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-simple-user", modifier, size, style)
@Composable fun PhFolderStar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-star", modifier, size, style)
@Composable fun PhFolderUser(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folder-user", modifier, size, style)
@Composable fun PhFolders(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-folders", modifier, size, style)
@Composable fun PhFootball(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-football", modifier, size, style)
@Composable fun PhFootprints(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-footprints", modifier, size, style)
@Composable fun PhForkKnife(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-fork-knife", modifier, size, style)
@Composable fun PhFrameCorners(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-frame-corners", modifier, size, style)
@Composable fun PhFramerLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-framer-logo", modifier, size, style)
@Composable fun PhFunction(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-function", modifier, size, style)
@Composable fun PhFunnel(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-funnel", modifier, size, style)
@Composable fun PhFunnelSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-funnel-simple", modifier, size, style)
@Composable fun PhGameController(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-game-controller", modifier, size, style)
@Composable fun PhGarage(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-garage", modifier, size, style)
@Composable fun PhGasCan(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gas-can", modifier, size, style)
@Composable fun PhGasPump(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gas-pump", modifier, size, style)
@Composable fun PhGauge(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gauge", modifier, size, style)
@Composable fun PhGavel(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gavel", modifier, size, style)
@Composable fun PhGear(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gear", modifier, size, style)
@Composable fun PhGearFine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gear-fine", modifier, size, style)
@Composable fun PhGearSix(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gear-six", modifier, size, style)
@Composable fun PhGenderFemale(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gender-female", modifier, size, style)
@Composable fun PhGenderIntersex(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gender-intersex", modifier, size, style)
@Composable fun PhGenderMale(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gender-male", modifier, size, style)
@Composable fun PhGenderNeuter(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gender-neuter", modifier, size, style)
@Composable fun PhGenderNonbinary(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gender-nonbinary", modifier, size, style)
@Composable fun PhGenderTransgender(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gender-transgender", modifier, size, style)
@Composable fun PhGhost(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-ghost", modifier, size, style)
@Composable fun PhGif(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gif", modifier, size, style)
@Composable fun PhGift(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gift", modifier, size, style)
@Composable fun PhGitBranch(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-git-branch", modifier, size, style)
@Composable fun PhGitCommit(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-git-commit", modifier, size, style)
@Composable fun PhGitDiff(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-git-diff", modifier, size, style)
@Composable fun PhGitFork(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-git-fork", modifier, size, style)
@Composable fun PhGitMerge(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-git-merge", modifier, size, style)
@Composable fun PhGitPullRequest(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-git-pull-request", modifier, size, style)
@Composable fun PhGithubLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-github-logo", modifier, size, style)
@Composable fun PhGitlabLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gitlab-logo", modifier, size, style)
@Composable fun PhGitlabLogoSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gitlab-logo-simple", modifier, size, style)
@Composable fun PhGlobe(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-globe", modifier, size, style)
@Composable fun PhGlobeHemisphereEast(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-globe-hemisphere-east", modifier, size, style)
@Composable fun PhGlobeHemisphereWest(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-globe-hemisphere-west", modifier, size, style)
@Composable fun PhGlobeSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-globe-simple", modifier, size, style)
@Composable fun PhGlobeStand(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-globe-stand", modifier, size, style)
@Composable fun PhGoggles(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-goggles", modifier, size, style)
@Composable fun PhGoodreadsLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-goodreads-logo", modifier, size, style)
@Composable fun PhGoogleCardboardLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-google-cardboard-logo", modifier, size, style)
@Composable fun PhGoogleChromeLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-google-chrome-logo", modifier, size, style)
@Composable fun PhGoogleDriveLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-google-drive-logo", modifier, size, style)
@Composable fun PhGoogleLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-google-logo", modifier, size, style)
@Composable fun PhGooglePhotosLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-google-photos-logo", modifier, size, style)
@Composable fun PhGooglePlayLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-google-play-logo", modifier, size, style)
@Composable fun PhGooglePodcastsLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-google-podcasts-logo", modifier, size, style)
@Composable fun PhGradient(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-gradient", modifier, size, style)
@Composable fun PhGraduationCap(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-graduation-cap", modifier, size, style)
@Composable fun PhGrains(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-grains", modifier, size, style)
@Composable fun PhGrainsSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-grains-slash", modifier, size, style)
@Composable fun PhGraph(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-graph", modifier, size, style)
@Composable fun PhGridFour(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-grid-four", modifier, size, style)
@Composable fun PhGridNine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-grid-nine", modifier, size, style)
@Composable fun PhGuitar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-guitar", modifier, size, style)
@Composable fun PhHamburger(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hamburger", modifier, size, style)
@Composable fun PhHammer(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hammer", modifier, size, style)
@Composable fun PhHand(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hand", modifier, size, style)
@Composable fun PhHandCoins(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hand-coins", modifier, size, style)
@Composable fun PhHandEye(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hand-eye", modifier, size, style)
@Composable fun PhHandFist(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hand-fist", modifier, size, style)
@Composable fun PhHandGrabbing(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hand-grabbing", modifier, size, style)
@Composable fun PhHandHeart(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hand-heart", modifier, size, style)
@Composable fun PhHandPalm(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hand-palm", modifier, size, style)
@Composable fun PhHandPointing(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hand-pointing", modifier, size, style)
@Composable fun PhHandSoap(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hand-soap", modifier, size, style)
@Composable fun PhHandSwipeLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hand-swipe-left", modifier, size, style)
@Composable fun PhHandSwipeRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hand-swipe-right", modifier, size, style)
@Composable fun PhHandTap(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hand-tap", modifier, size, style)
@Composable fun PhHandWaving(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hand-waving", modifier, size, style)
@Composable fun PhHandbag(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-handbag", modifier, size, style)
@Composable fun PhHandbagSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-handbag-simple", modifier, size, style)
@Composable fun PhHandsClapping(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hands-clapping", modifier, size, style)
@Composable fun PhHandsPraying(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hands-praying", modifier, size, style)
@Composable fun PhHandshake(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-handshake", modifier, size, style)
@Composable fun PhHardDrive(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hard-drive", modifier, size, style)
@Composable fun PhHardDrives(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hard-drives", modifier, size, style)
@Composable fun PhHash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hash", modifier, size, style)
@Composable fun PhHashStraight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hash-straight", modifier, size, style)
@Composable fun PhHeadlights(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-headlights", modifier, size, style)
@Composable fun PhHeadphones(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-headphones", modifier, size, style)
@Composable fun PhHeadset(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-headset", modifier, size, style)
@Composable fun PhHeart(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-heart", modifier, size, style)
@Composable fun PhHeartBreak(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-heart-break", modifier, size, style)
@Composable fun PhHeartHalf(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-heart-half", modifier, size, style)
@Composable fun PhHeartStraight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-heart-straight", modifier, size, style)
@Composable fun PhHeartStraightBreak(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-heart-straight-break", modifier, size, style)
@Composable fun PhHeartbeat(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-heartbeat", modifier, size, style)
@Composable fun PhHexagon(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hexagon", modifier, size, style)
@Composable fun PhHighHeel(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-high-heel", modifier, size, style)
@Composable fun PhHighlighterCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-highlighter-circle", modifier, size, style)
@Composable fun PhHoodie(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hoodie", modifier, size, style)
@Composable fun PhHorse(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-horse", modifier, size, style)
@Composable fun PhHourglass(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hourglass", modifier, size, style)
@Composable fun PhHourglassHigh(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hourglass-high", modifier, size, style)
@Composable fun PhHourglassLow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hourglass-low", modifier, size, style)
@Composable fun PhHourglassMedium(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hourglass-medium", modifier, size, style)
@Composable fun PhHourglassSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hourglass-simple", modifier, size, style)
@Composable fun PhHourglassSimpleHigh(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hourglass-simple-high", modifier, size, style)
@Composable fun PhHourglassSimpleLow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hourglass-simple-low", modifier, size, style)
@Composable fun PhHourglassSimpleMedium(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-hourglass-simple-medium", modifier, size, style)
@Composable fun PhHouse(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-house", modifier, size, style)
@Composable fun PhHouseLine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-house-line", modifier, size, style)
@Composable fun PhHouseSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-house-simple", modifier, size, style)
@Composable fun PhIceCream(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-ice-cream", modifier, size, style)
@Composable fun PhIdentificationBadge(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-identification-badge", modifier, size, style)
@Composable fun PhIdentificationCard(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-identification-card", modifier, size, style)
@Composable fun PhImage(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-image", modifier, size, style)
@Composable fun PhImageSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-image-square", modifier, size, style)
@Composable fun PhImages(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-images", modifier, size, style)
@Composable fun PhImagesSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-images-square", modifier, size, style)
@Composable fun PhInfinity(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-infinity", modifier, size, style)
@Composable fun PhInfo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-info", modifier, size, style)
@Composable fun PhInstagramLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-instagram-logo", modifier, size, style)
@Composable fun PhIntersect(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-intersect", modifier, size, style)
@Composable fun PhIntersectSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-intersect-square", modifier, size, style)
@Composable fun PhIntersectThree(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-intersect-three", modifier, size, style)
@Composable fun PhJeep(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-jeep", modifier, size, style)
@Composable fun PhKanban(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-kanban", modifier, size, style)
@Composable fun PhKey(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-key", modifier, size, style)
@Composable fun PhKeyReturn(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-key-return", modifier, size, style)
@Composable fun PhKeyboard(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-keyboard", modifier, size, style)
@Composable fun PhKeyhole(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-keyhole", modifier, size, style)
@Composable fun PhKnife(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-knife", modifier, size, style)
@Composable fun PhLadder(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-ladder", modifier, size, style)
@Composable fun PhLadderSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-ladder-simple", modifier, size, style)
@Composable fun PhLamp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lamp", modifier, size, style)
@Composable fun PhLaptop(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-laptop", modifier, size, style)
@Composable fun PhLayout(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-layout", modifier, size, style)
@Composable fun PhLeaf(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-leaf", modifier, size, style)
@Composable fun PhLifebuoy(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lifebuoy", modifier, size, style)
@Composable fun PhLightbulb(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lightbulb", modifier, size, style)
@Composable fun PhLightbulbFilament(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lightbulb-filament", modifier, size, style)
@Composable fun PhLighthouse(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lighthouse", modifier, size, style)
@Composable fun PhLightning(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lightning", modifier, size, style)
@Composable fun PhLightningA(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lightning-a", modifier, size, style)
@Composable fun PhLightningSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lightning-slash", modifier, size, style)
@Composable fun PhLineSegment(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-line-segment", modifier, size, style)
@Composable fun PhLineSegments(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-line-segments", modifier, size, style)
@Composable fun PhLink(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-link", modifier, size, style)
@Composable fun PhLinkBreak(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-link-break", modifier, size, style)
@Composable fun PhLinkSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-link-simple", modifier, size, style)
@Composable fun PhLinkSimpleBreak(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-link-simple-break", modifier, size, style)
@Composable fun PhLinkSimpleHorizontal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-link-simple-horizontal", modifier, size, style)
@Composable fun PhLinkSimpleHorizontalBreak(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-link-simple-horizontal-break", modifier, size, style)
@Composable fun PhLinkedinLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-linkedin-logo", modifier, size, style)
@Composable fun PhLinuxLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-linux-logo", modifier, size, style)
@Composable fun PhList(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-list", modifier, size, style)
@Composable fun PhListBullets(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-list-bullets", modifier, size, style)
@Composable fun PhListChecks(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-list-checks", modifier, size, style)
@Composable fun PhListDashes(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-list-dashes", modifier, size, style)
@Composable fun PhListMagnifyingGlass(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-list-magnifying-glass", modifier, size, style)
@Composable fun PhListNumbers(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-list-numbers", modifier, size, style)
@Composable fun PhListPlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-list-plus", modifier, size, style)
@Composable fun PhLock(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lock", modifier, size, style)
@Composable fun PhLockKey(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lock-key", modifier, size, style)
@Composable fun PhLockKeyOpen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lock-key-open", modifier, size, style)
@Composable fun PhLockLaminated(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lock-laminated", modifier, size, style)
@Composable fun PhLockLaminatedOpen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lock-laminated-open", modifier, size, style)
@Composable fun PhLockOpen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lock-open", modifier, size, style)
@Composable fun PhLockSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lock-simple", modifier, size, style)
@Composable fun PhLockSimpleOpen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lock-simple-open", modifier, size, style)
@Composable fun PhLockers(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-lockers", modifier, size, style)
@Composable fun PhMagicWand(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-magic-wand", modifier, size, style)
@Composable fun PhMagnet(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-magnet", modifier, size, style)
@Composable fun PhMagnetStraight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-magnet-straight", modifier, size, style)
@Composable fun PhMagnifyingGlass(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-magnifying-glass", modifier, size, style)
@Composable fun PhMagnifyingGlassMinus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-magnifying-glass-minus", modifier, size, style)
@Composable fun PhMagnifyingGlassPlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-magnifying-glass-plus", modifier, size, style)
@Composable fun PhMapPin(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-map-pin", modifier, size, style)
@Composable fun PhMapPinLine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-map-pin-line", modifier, size, style)
@Composable fun PhMapTrifold(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-map-trifold", modifier, size, style)
@Composable fun PhMarkerCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-marker-circle", modifier, size, style)
@Composable fun PhMartini(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-martini", modifier, size, style)
@Composable fun PhMaskHappy(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-mask-happy", modifier, size, style)
@Composable fun PhMaskSad(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-mask-sad", modifier, size, style)
@Composable fun PhMathOperations(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-math-operations", modifier, size, style)
@Composable fun PhMedal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-medal", modifier, size, style)
@Composable fun PhMedalMilitary(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-medal-military", modifier, size, style)
@Composable fun PhMediumLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-medium-logo", modifier, size, style)
@Composable fun PhMegaphone(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-megaphone", modifier, size, style)
@Composable fun PhMegaphoneSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-megaphone-simple", modifier, size, style)
@Composable fun PhMessengerLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-messenger-logo", modifier, size, style)
@Composable fun PhMetaLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-meta-logo", modifier, size, style)
@Composable fun PhMetronome(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-metronome", modifier, size, style)
@Composable fun PhMicrophone(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-microphone", modifier, size, style)
@Composable fun PhMicrophoneSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-microphone-slash", modifier, size, style)
@Composable fun PhMicrophoneStage(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-microphone-stage", modifier, size, style)
@Composable fun PhMicrosoftExcelLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-microsoft-excel-logo", modifier, size, style)
@Composable fun PhMicrosoftOutlookLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-microsoft-outlook-logo", modifier, size, style)
@Composable fun PhMicrosoftPowerpointLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-microsoft-powerpoint-logo", modifier, size, style)
@Composable fun PhMicrosoftTeamsLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-microsoft-teams-logo", modifier, size, style)
@Composable fun PhMicrosoftWordLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-microsoft-word-logo", modifier, size, style)
@Composable fun PhMinus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-minus", modifier, size, style)
@Composable fun PhMinusCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-minus-circle", modifier, size, style)
@Composable fun PhMinusSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-minus-square", modifier, size, style)
@Composable fun PhMoney(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-money", modifier, size, style)
@Composable fun PhMonitor(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-monitor", modifier, size, style)
@Composable fun PhMonitorPlay(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-monitor-play", modifier, size, style)
@Composable fun PhMoon(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-moon", modifier, size, style)
@Composable fun PhMoonStars(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-moon-stars", modifier, size, style)
@Composable fun PhMoped(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-moped", modifier, size, style)
@Composable fun PhMopedFront(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-moped-front", modifier, size, style)
@Composable fun PhMosque(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-mosque", modifier, size, style)
@Composable fun PhMotorcycle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-motorcycle", modifier, size, style)
@Composable fun PhMountains(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-mountains", modifier, size, style)
@Composable fun PhMouse(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-mouse", modifier, size, style)
@Composable fun PhMouseSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-mouse-simple", modifier, size, style)
@Composable fun PhMusicNote(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-music-note", modifier, size, style)
@Composable fun PhMusicNoteSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-music-note-simple", modifier, size, style)
@Composable fun PhMusicNotes(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-music-notes", modifier, size, style)
@Composable fun PhMusicNotesPlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-music-notes-plus", modifier, size, style)
@Composable fun PhMusicNotesSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-music-notes-simple", modifier, size, style)
@Composable fun PhNavigationArrow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-navigation-arrow", modifier, size, style)
@Composable fun PhNeedle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-needle", modifier, size, style)
@Composable fun PhNewspaper(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-newspaper", modifier, size, style)
@Composable fun PhNewspaperClipping(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-newspaper-clipping", modifier, size, style)
@Composable fun PhNotches(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-notches", modifier, size, style)
@Composable fun PhNote(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-note", modifier, size, style)
@Composable fun PhNoteBlank(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-note-blank", modifier, size, style)
@Composable fun PhNotePencil(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-note-pencil", modifier, size, style)
@Composable fun PhNotebook(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-notebook", modifier, size, style)
@Composable fun PhNotepad(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-notepad", modifier, size, style)
@Composable fun PhNotification(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-notification", modifier, size, style)
@Composable fun PhNotionLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-notion-logo", modifier, size, style)
@Composable fun PhNumberCircleEight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-circle-eight", modifier, size, style)
@Composable fun PhNumberCircleFive(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-circle-five", modifier, size, style)
@Composable fun PhNumberCircleFour(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-circle-four", modifier, size, style)
@Composable fun PhNumberCircleNine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-circle-nine", modifier, size, style)
@Composable fun PhNumberCircleOne(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-circle-one", modifier, size, style)
@Composable fun PhNumberCircleSeven(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-circle-seven", modifier, size, style)
@Composable fun PhNumberCircleSix(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-circle-six", modifier, size, style)
@Composable fun PhNumberCircleThree(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-circle-three", modifier, size, style)
@Composable fun PhNumberCircleTwo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-circle-two", modifier, size, style)
@Composable fun PhNumberCircleZero(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-circle-zero", modifier, size, style)
@Composable fun PhNumberEight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-eight", modifier, size, style)
@Composable fun PhNumberFive(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-five", modifier, size, style)
@Composable fun PhNumberFour(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-four", modifier, size, style)
@Composable fun PhNumberNine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-nine", modifier, size, style)
@Composable fun PhNumberOne(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-one", modifier, size, style)
@Composable fun PhNumberSeven(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-seven", modifier, size, style)
@Composable fun PhNumberSix(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-six", modifier, size, style)
@Composable fun PhNumberSquareEight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-square-eight", modifier, size, style)
@Composable fun PhNumberSquareFive(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-square-five", modifier, size, style)
@Composable fun PhNumberSquareFour(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-square-four", modifier, size, style)
@Composable fun PhNumberSquareNine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-square-nine", modifier, size, style)
@Composable fun PhNumberSquareOne(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-square-one", modifier, size, style)
@Composable fun PhNumberSquareSeven(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-square-seven", modifier, size, style)
@Composable fun PhNumberSquareSix(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-square-six", modifier, size, style)
@Composable fun PhNumberSquareThree(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-square-three", modifier, size, style)
@Composable fun PhNumberSquareTwo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-square-two", modifier, size, style)
@Composable fun PhNumberSquareZero(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-square-zero", modifier, size, style)
@Composable fun PhNumberThree(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-three", modifier, size, style)
@Composable fun PhNumberTwo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-two", modifier, size, style)
@Composable fun PhNumberZero(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-number-zero", modifier, size, style)
@Composable fun PhNut(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-nut", modifier, size, style)
@Composable fun PhNyTimesLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-ny-times-logo", modifier, size, style)
@Composable fun PhOctagon(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-octagon", modifier, size, style)
@Composable fun PhOfficeChair(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-office-chair", modifier, size, style)
@Composable fun PhOption(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-option", modifier, size, style)
@Composable fun PhOrangeSlice(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-orange-slice", modifier, size, style)
@Composable fun PhPackage(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-package", modifier, size, style)
@Composable fun PhPaintBrush(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-paint-brush", modifier, size, style)
@Composable fun PhPaintBrushBroad(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-paint-brush-broad", modifier, size, style)
@Composable fun PhPaintBrushHousehold(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-paint-brush-household", modifier, size, style)
@Composable fun PhPaintBucket(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-paint-bucket", modifier, size, style)
@Composable fun PhPaintRoller(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-paint-roller", modifier, size, style)
@Composable fun PhPalette(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-palette", modifier, size, style)
@Composable fun PhPants(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pants", modifier, size, style)
@Composable fun PhPaperPlane(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-paper-plane", modifier, size, style)
@Composable fun PhPaperPlaneRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-paper-plane-right", modifier, size, style)
@Composable fun PhPaperPlaneTilt(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-paper-plane-tilt", modifier, size, style)
@Composable fun PhPaperclip(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-paperclip", modifier, size, style)
@Composable fun PhPaperclipHorizontal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-paperclip-horizontal", modifier, size, style)
@Composable fun PhParachute(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-parachute", modifier, size, style)
@Composable fun PhParagraph(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-paragraph", modifier, size, style)
@Composable fun PhParallelogram(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-parallelogram", modifier, size, style)
@Composable fun PhPark(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-park", modifier, size, style)
@Composable fun PhPassword(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-password", modifier, size, style)
@Composable fun PhPath(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-path", modifier, size, style)
@Composable fun PhPatreonLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-patreon-logo", modifier, size, style)
@Composable fun PhPause(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pause", modifier, size, style)
@Composable fun PhPauseCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pause-circle", modifier, size, style)
@Composable fun PhPawPrint(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-paw-print", modifier, size, style)
@Composable fun PhPaypalLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-paypal-logo", modifier, size, style)
@Composable fun PhPeace(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-peace", modifier, size, style)
@Composable fun PhPen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pen", modifier, size, style)
@Composable fun PhPenNib(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pen-nib", modifier, size, style)
@Composable fun PhPenNibStraight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pen-nib-straight", modifier, size, style)
@Composable fun PhPencil(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pencil", modifier, size, style)
@Composable fun PhPencilCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pencil-circle", modifier, size, style)
@Composable fun PhPencilLine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pencil-line", modifier, size, style)
@Composable fun PhPencilSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pencil-simple", modifier, size, style)
@Composable fun PhPencilSimpleLine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pencil-simple-line", modifier, size, style)
@Composable fun PhPencilSimpleSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pencil-simple-slash", modifier, size, style)
@Composable fun PhPencilSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pencil-slash", modifier, size, style)
@Composable fun PhPentagram(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pentagram", modifier, size, style)
@Composable fun PhPepper(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pepper", modifier, size, style)
@Composable fun PhPercent(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-percent", modifier, size, style)
@Composable fun PhPerson(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-person", modifier, size, style)
@Composable fun PhPersonArmsSpread(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-person-arms-spread", modifier, size, style)
@Composable fun PhPersonSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-person-simple", modifier, size, style)
@Composable fun PhPersonSimpleBike(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-person-simple-bike", modifier, size, style)
@Composable fun PhPersonSimpleRun(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-person-simple-run", modifier, size, style)
@Composable fun PhPersonSimpleThrow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-person-simple-throw", modifier, size, style)
@Composable fun PhPersonSimpleWalk(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-person-simple-walk", modifier, size, style)
@Composable fun PhPerspective(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-perspective", modifier, size, style)
@Composable fun PhPhone(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-phone", modifier, size, style)
@Composable fun PhPhoneCall(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-phone-call", modifier, size, style)
@Composable fun PhPhoneDisconnect(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-phone-disconnect", modifier, size, style)
@Composable fun PhPhoneIncoming(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-phone-incoming", modifier, size, style)
@Composable fun PhPhoneOutgoing(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-phone-outgoing", modifier, size, style)
@Composable fun PhPhonePlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-phone-plus", modifier, size, style)
@Composable fun PhPhoneSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-phone-slash", modifier, size, style)
@Composable fun PhPhoneX(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-phone-x", modifier, size, style)
@Composable fun PhPhosphorLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-phosphor-logo", modifier, size, style)
@Composable fun PhPi(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pi", modifier, size, style)
@Composable fun PhPianoKeys(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-piano-keys", modifier, size, style)
@Composable fun PhPictureInPicture(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-picture-in-picture", modifier, size, style)
@Composable fun PhPiggyBank(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-piggy-bank", modifier, size, style)
@Composable fun PhPill(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pill", modifier, size, style)
@Composable fun PhPinterestLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pinterest-logo", modifier, size, style)
@Composable fun PhPinwheel(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pinwheel", modifier, size, style)
@Composable fun PhPizza(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pizza", modifier, size, style)
@Composable fun PhPlaceholder(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-placeholder", modifier, size, style)
@Composable fun PhPlanet(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-planet", modifier, size, style)
@Composable fun PhPlant(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-plant", modifier, size, style)
@Composable fun PhPlay(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-play", modifier, size, style)
@Composable fun PhPlayCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-play-circle", modifier, size, style)
@Composable fun PhPlayPause(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-play-pause", modifier, size, style)
@Composable fun PhPlaylist(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-playlist", modifier, size, style)
@Composable fun PhPlug(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-plug", modifier, size, style)
@Composable fun PhPlugCharging(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-plug-charging", modifier, size, style)
@Composable fun PhPlugs(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-plugs", modifier, size, style)
@Composable fun PhPlugsConnected(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-plugs-connected", modifier, size, style)
@Composable fun PhPlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-plus", modifier, size, style)
@Composable fun PhPlusCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-plus-circle", modifier, size, style)
@Composable fun PhPlusMinus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-plus-minus", modifier, size, style)
@Composable fun PhPlusSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-plus-square", modifier, size, style)
@Composable fun PhPokerChip(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-poker-chip", modifier, size, style)
@Composable fun PhPoliceCar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-police-car", modifier, size, style)
@Composable fun PhPolygon(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-polygon", modifier, size, style)
@Composable fun PhPopcorn(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-popcorn", modifier, size, style)
@Composable fun PhPottedPlant(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-potted-plant", modifier, size, style)
@Composable fun PhPower(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-power", modifier, size, style)
@Composable fun PhPrescription(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-prescription", modifier, size, style)
@Composable fun PhPresentation(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-presentation", modifier, size, style)
@Composable fun PhPresentationChart(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-presentation-chart", modifier, size, style)
@Composable fun PhPrinter(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-printer", modifier, size, style)
@Composable fun PhProhibit(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-prohibit", modifier, size, style)
@Composable fun PhProhibitInset(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-prohibit-inset", modifier, size, style)
@Composable fun PhProjectorScreen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-projector-screen", modifier, size, style)
@Composable fun PhProjectorScreenChart(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-projector-screen-chart", modifier, size, style)
@Composable fun PhPulse(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-pulse", modifier, size, style)
@Composable fun PhActivity(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-activity", modifier, size, style)
@Composable fun PhPushPin(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-push-pin", modifier, size, style)
@Composable fun PhPushPinSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-push-pin-simple", modifier, size, style)
@Composable fun PhPushPinSimpleSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-push-pin-simple-slash", modifier, size, style)
@Composable fun PhPushPinSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-push-pin-slash", modifier, size, style)
@Composable fun PhPuzzlePiece(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-puzzle-piece", modifier, size, style)
@Composable fun PhQrCode(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-qr-code", modifier, size, style)
@Composable fun PhQuestion(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-question", modifier, size, style)
@Composable fun PhQueue(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-queue", modifier, size, style)
@Composable fun PhQuotes(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-quotes", modifier, size, style)
@Composable fun PhRadical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-radical", modifier, size, style)
@Composable fun PhRadio(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-radio", modifier, size, style)
@Composable fun PhRadioButton(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-radio-button", modifier, size, style)
@Composable fun PhRadioactive(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-radioactive", modifier, size, style)
@Composable fun PhRainbow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-rainbow", modifier, size, style)
@Composable fun PhRainbowCloud(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-rainbow-cloud", modifier, size, style)
@Composable fun PhReadCvLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-read-cv-logo", modifier, size, style)
@Composable fun PhReceipt(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-receipt", modifier, size, style)
@Composable fun PhReceiptX(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-receipt-x", modifier, size, style)
@Composable fun PhRecord(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-record", modifier, size, style)
@Composable fun PhRectangle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-rectangle", modifier, size, style)
@Composable fun PhRecycle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-recycle", modifier, size, style)
@Composable fun PhRedditLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-reddit-logo", modifier, size, style)
@Composable fun PhRepeat(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-repeat", modifier, size, style)
@Composable fun PhRepeatOnce(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-repeat-once", modifier, size, style)
@Composable fun PhRewind(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-rewind", modifier, size, style)
@Composable fun PhRewindCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-rewind-circle", modifier, size, style)
@Composable fun PhRoadHorizon(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-road-horizon", modifier, size, style)
@Composable fun PhRobot(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-robot", modifier, size, style)
@Composable fun PhRocket(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-rocket", modifier, size, style)
@Composable fun PhRocketLaunch(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-rocket-launch", modifier, size, style)
@Composable fun PhRows(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-rows", modifier, size, style)
@Composable fun PhRss(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-rss", modifier, size, style)
@Composable fun PhRssSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-rss-simple", modifier, size, style)
@Composable fun PhRug(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-rug", modifier, size, style)
@Composable fun PhRuler(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-ruler", modifier, size, style)
@Composable fun PhScales(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-scales", modifier, size, style)
@Composable fun PhScan(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-scan", modifier, size, style)
@Composable fun PhScissors(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-scissors", modifier, size, style)
@Composable fun PhScooter(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-scooter", modifier, size, style)
@Composable fun PhScreencast(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-screencast", modifier, size, style)
@Composable fun PhScribbleLoop(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-scribble-loop", modifier, size, style)
@Composable fun PhScroll(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-scroll", modifier, size, style)
@Composable fun PhSeal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-seal", modifier, size, style)
@Composable fun PhCircleWavy(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-circle-wavy", modifier, size, style)
@Composable fun PhSealCheck(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-seal-check", modifier, size, style)
@Composable fun PhCircleWavyCheck(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-circle-wavy-check", modifier, size, style)
@Composable fun PhSealQuestion(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-seal-question", modifier, size, style)
@Composable fun PhCircleWavyQuestion(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-circle-wavy-question", modifier, size, style)
@Composable fun PhSealWarning(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-seal-warning", modifier, size, style)
@Composable fun PhCircleWavyWarning(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-circle-wavy-warning", modifier, size, style)
@Composable fun PhSelection(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-selection", modifier, size, style)
@Composable fun PhSelectionAll(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-selection-all", modifier, size, style)
@Composable fun PhSelectionBackground(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-selection-background", modifier, size, style)
@Composable fun PhSelectionForeground(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-selection-foreground", modifier, size, style)
@Composable fun PhSelectionInverse(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-selection-inverse", modifier, size, style)
@Composable fun PhSelectionPlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-selection-plus", modifier, size, style)
@Composable fun PhSelectionSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-selection-slash", modifier, size, style)
@Composable fun PhShapes(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shapes", modifier, size, style)
@Composable fun PhShare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-share", modifier, size, style)
@Composable fun PhShareFat(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-share-fat", modifier, size, style)
@Composable fun PhShareNetwork(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-share-network", modifier, size, style)
@Composable fun PhShield(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shield", modifier, size, style)
@Composable fun PhShieldCheck(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shield-check", modifier, size, style)
@Composable fun PhShieldCheckered(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shield-checkered", modifier, size, style)
@Composable fun PhShieldChevron(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shield-chevron", modifier, size, style)
@Composable fun PhShieldPlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shield-plus", modifier, size, style)
@Composable fun PhShieldSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shield-slash", modifier, size, style)
@Composable fun PhShieldStar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shield-star", modifier, size, style)
@Composable fun PhShieldWarning(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shield-warning", modifier, size, style)
@Composable fun PhShirtFolded(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shirt-folded", modifier, size, style)
@Composable fun PhShootingStar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shooting-star", modifier, size, style)
@Composable fun PhShoppingBag(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shopping-bag", modifier, size, style)
@Composable fun PhShoppingBagOpen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shopping-bag-open", modifier, size, style)
@Composable fun PhShoppingCart(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shopping-cart", modifier, size, style)
@Composable fun PhShoppingCartSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shopping-cart-simple", modifier, size, style)
@Composable fun PhShower(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shower", modifier, size, style)
@Composable fun PhShrimp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shrimp", modifier, size, style)
@Composable fun PhShuffle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shuffle", modifier, size, style)
@Composable fun PhShuffleAngular(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shuffle-angular", modifier, size, style)
@Composable fun PhShuffleSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-shuffle-simple", modifier, size, style)
@Composable fun PhSidebar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sidebar", modifier, size, style)
@Composable fun PhSidebarSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sidebar-simple", modifier, size, style)
@Composable fun PhSigma(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sigma", modifier, size, style)
@Composable fun PhSignIn(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sign-in", modifier, size, style)
@Composable fun PhSignOut(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sign-out", modifier, size, style)
@Composable fun PhSignature(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-signature", modifier, size, style)
@Composable fun PhSignpost(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-signpost", modifier, size, style)
@Composable fun PhSimCard(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sim-card", modifier, size, style)
@Composable fun PhSiren(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-siren", modifier, size, style)
@Composable fun PhSketchLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sketch-logo", modifier, size, style)
@Composable fun PhSkipBack(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-skip-back", modifier, size, style)
@Composable fun PhSkipBackCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-skip-back-circle", modifier, size, style)
@Composable fun PhSkipForward(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-skip-forward", modifier, size, style)
@Composable fun PhSkipForwardCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-skip-forward-circle", modifier, size, style)
@Composable fun PhSkull(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-skull", modifier, size, style)
@Composable fun PhSlackLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-slack-logo", modifier, size, style)
@Composable fun PhSliders(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sliders", modifier, size, style)
@Composable fun PhSlidersHorizontal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sliders-horizontal", modifier, size, style)
@Composable fun PhSlideshow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-slideshow", modifier, size, style)
@Composable fun PhSmiley(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-smiley", modifier, size, style)
@Composable fun PhSmileyAngry(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-smiley-angry", modifier, size, style)
@Composable fun PhSmileyBlank(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-smiley-blank", modifier, size, style)
@Composable fun PhSmileyMeh(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-smiley-meh", modifier, size, style)
@Composable fun PhSmileyNervous(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-smiley-nervous", modifier, size, style)
@Composable fun PhSmileySad(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-smiley-sad", modifier, size, style)
@Composable fun PhSmileySticker(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-smiley-sticker", modifier, size, style)
@Composable fun PhSmileyWink(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-smiley-wink", modifier, size, style)
@Composable fun PhSmileyXEyes(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-smiley-x-eyes", modifier, size, style)
@Composable fun PhSnapchatLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-snapchat-logo", modifier, size, style)
@Composable fun PhSneaker(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sneaker", modifier, size, style)
@Composable fun PhSneakerMove(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sneaker-move", modifier, size, style)
@Composable fun PhSnowflake(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-snowflake", modifier, size, style)
@Composable fun PhSoccerBall(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-soccer-ball", modifier, size, style)
@Composable fun PhSortAscending(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sort-ascending", modifier, size, style)
@Composable fun PhSortDescending(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sort-descending", modifier, size, style)
@Composable fun PhSoundcloudLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-soundcloud-logo", modifier, size, style)
@Composable fun PhSpade(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-spade", modifier, size, style)
@Composable fun PhSparkle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sparkle", modifier, size, style)
@Composable fun PhSpeakerHifi(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-speaker-hifi", modifier, size, style)
@Composable fun PhSpeakerHigh(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-speaker-high", modifier, size, style)
@Composable fun PhSpeakerLow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-speaker-low", modifier, size, style)
@Composable fun PhSpeakerNone(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-speaker-none", modifier, size, style)
@Composable fun PhSpeakerSimpleHigh(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-speaker-simple-high", modifier, size, style)
@Composable fun PhSpeakerSimpleLow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-speaker-simple-low", modifier, size, style)
@Composable fun PhSpeakerSimpleNone(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-speaker-simple-none", modifier, size, style)
@Composable fun PhSpeakerSimpleSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-speaker-simple-slash", modifier, size, style)
@Composable fun PhSpeakerSimpleX(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-speaker-simple-x", modifier, size, style)
@Composable fun PhSpeakerSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-speaker-slash", modifier, size, style)
@Composable fun PhSpeakerX(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-speaker-x", modifier, size, style)
@Composable fun PhSpinner(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-spinner", modifier, size, style)
@Composable fun PhSpinnerGap(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-spinner-gap", modifier, size, style)
@Composable fun PhSpiral(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-spiral", modifier, size, style)
@Composable fun PhSplitHorizontal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-split-horizontal", modifier, size, style)
@Composable fun PhSplitVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-split-vertical", modifier, size, style)
@Composable fun PhSpotifyLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-spotify-logo", modifier, size, style)
@Composable fun PhSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-square", modifier, size, style)
@Composable fun PhSquareHalf(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-square-half", modifier, size, style)
@Composable fun PhSquareHalfBottom(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-square-half-bottom", modifier, size, style)
@Composable fun PhSquareLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-square-logo", modifier, size, style)
@Composable fun PhSquareSplitHorizontal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-square-split-horizontal", modifier, size, style)
@Composable fun PhSquareSplitVertical(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-square-split-vertical", modifier, size, style)
@Composable fun PhSquaresFour(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-squares-four", modifier, size, style)
@Composable fun PhStack(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-stack", modifier, size, style)
@Composable fun PhStackOverflowLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-stack-overflow-logo", modifier, size, style)
@Composable fun PhStackSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-stack-simple", modifier, size, style)
@Composable fun PhStairs(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-stairs", modifier, size, style)
@Composable fun PhStamp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-stamp", modifier, size, style)
@Composable fun PhStar(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-star", modifier, size, style)
@Composable fun PhStarAndCrescent(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-star-and-crescent", modifier, size, style)
@Composable fun PhStarFour(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-star-four", modifier, size, style)
@Composable fun PhStarHalf(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-star-half", modifier, size, style)
@Composable fun PhStarOfDavid(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-star-of-david", modifier, size, style)
@Composable fun PhSteeringWheel(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-steering-wheel", modifier, size, style)
@Composable fun PhSteps(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-steps", modifier, size, style)
@Composable fun PhStethoscope(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-stethoscope", modifier, size, style)
@Composable fun PhSticker(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sticker", modifier, size, style)
@Composable fun PhStool(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-stool", modifier, size, style)
@Composable fun PhStop(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-stop", modifier, size, style)
@Composable fun PhStopCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-stop-circle", modifier, size, style)
@Composable fun PhStorefront(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-storefront", modifier, size, style)
@Composable fun PhStrategy(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-strategy", modifier, size, style)
@Composable fun PhStripeLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-stripe-logo", modifier, size, style)
@Composable fun PhStudent(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-student", modifier, size, style)
@Composable fun PhSubtitles(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-subtitles", modifier, size, style)
@Composable fun PhSubtract(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-subtract", modifier, size, style)
@Composable fun PhSubtractSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-subtract-square", modifier, size, style)
@Composable fun PhSuitcase(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-suitcase", modifier, size, style)
@Composable fun PhSuitcaseRolling(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-suitcase-rolling", modifier, size, style)
@Composable fun PhSuitcaseSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-suitcase-simple", modifier, size, style)
@Composable fun PhSun(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sun", modifier, size, style)
@Composable fun PhSunDim(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sun-dim", modifier, size, style)
@Composable fun PhSunHorizon(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sun-horizon", modifier, size, style)
@Composable fun PhSunglasses(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sunglasses", modifier, size, style)
@Composable fun PhSwap(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-swap", modifier, size, style)
@Composable fun PhSwatches(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-swatches", modifier, size, style)
@Composable fun PhSwimmingPool(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-swimming-pool", modifier, size, style)
@Composable fun PhSword(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-sword", modifier, size, style)
@Composable fun PhSynagogue(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-synagogue", modifier, size, style)
@Composable fun PhSyringe(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-syringe", modifier, size, style)
@Composable fun PhTShirt(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-t-shirt", modifier, size, style)
@Composable fun PhTable(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-table", modifier, size, style)
@Composable fun PhTabs(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tabs", modifier, size, style)
@Composable fun PhTag(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tag", modifier, size, style)
@Composable fun PhTagChevron(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tag-chevron", modifier, size, style)
@Composable fun PhTagSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tag-simple", modifier, size, style)
@Composable fun PhTarget(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-target", modifier, size, style)
@Composable fun PhTaxi(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-taxi", modifier, size, style)
@Composable fun PhTelegramLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-telegram-logo", modifier, size, style)
@Composable fun PhTelevision(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-television", modifier, size, style)
@Composable fun PhTelevisionSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-television-simple", modifier, size, style)
@Composable fun PhTennisBall(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tennis-ball", modifier, size, style)
@Composable fun PhTent(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tent", modifier, size, style)
@Composable fun PhTerminal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-terminal", modifier, size, style)
@Composable fun PhTerminalWindow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-terminal-window", modifier, size, style)
@Composable fun PhTestTube(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-test-tube", modifier, size, style)
@Composable fun PhTextAUnderline(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-a-underline", modifier, size, style)
@Composable fun PhTextAa(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-aa", modifier, size, style)
@Composable fun PhTextAlignCenter(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-align-center", modifier, size, style)
@Composable fun PhTextAlignJustify(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-align-justify", modifier, size, style)
@Composable fun PhTextAlignLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-align-left", modifier, size, style)
@Composable fun PhTextAlignRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-align-right", modifier, size, style)
@Composable fun PhTextB(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-b", modifier, size, style)
@Composable fun PhTextBolder(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-bolder", modifier, size, style)
@Composable fun PhTextColumns(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-columns", modifier, size, style)
@Composable fun PhTextH(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-h", modifier, size, style)
@Composable fun PhTextHFive(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-h-five", modifier, size, style)
@Composable fun PhTextHFour(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-h-four", modifier, size, style)
@Composable fun PhTextHOne(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-h-one", modifier, size, style)
@Composable fun PhTextHSix(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-h-six", modifier, size, style)
@Composable fun PhTextHThree(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-h-three", modifier, size, style)
@Composable fun PhTextHTwo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-h-two", modifier, size, style)
@Composable fun PhTextIndent(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-indent", modifier, size, style)
@Composable fun PhTextItalic(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-italic", modifier, size, style)
@Composable fun PhTextOutdent(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-outdent", modifier, size, style)
@Composable fun PhTextStrikethrough(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-strikethrough", modifier, size, style)
@Composable fun PhTextT(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-t", modifier, size, style)
@Composable fun PhTextUnderline(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-text-underline", modifier, size, style)
@Composable fun PhTextbox(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-textbox", modifier, size, style)
@Composable fun PhThermometer(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-thermometer", modifier, size, style)
@Composable fun PhThermometerCold(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-thermometer-cold", modifier, size, style)
@Composable fun PhThermometerHot(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-thermometer-hot", modifier, size, style)
@Composable fun PhThermometerSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-thermometer-simple", modifier, size, style)
@Composable fun PhThumbsDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-thumbs-down", modifier, size, style)
@Composable fun PhThumbsUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-thumbs-up", modifier, size, style)
@Composable fun PhTicket(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-ticket", modifier, size, style)
@Composable fun PhTidalLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tidal-logo", modifier, size, style)
@Composable fun PhTiktokLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tiktok-logo", modifier, size, style)
@Composable fun PhTimer(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-timer", modifier, size, style)
@Composable fun PhTipi(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tipi", modifier, size, style)
@Composable fun PhToggleLeft(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-toggle-left", modifier, size, style)
@Composable fun PhToggleRight(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-toggle-right", modifier, size, style)
@Composable fun PhToilet(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-toilet", modifier, size, style)
@Composable fun PhToiletPaper(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-toilet-paper", modifier, size, style)
@Composable fun PhToolbox(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-toolbox", modifier, size, style)
@Composable fun PhTooth(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tooth", modifier, size, style)
@Composable fun PhTote(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tote", modifier, size, style)
@Composable fun PhToteSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tote-simple", modifier, size, style)
@Composable fun PhTrademark(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-trademark", modifier, size, style)
@Composable fun PhTrademarkRegistered(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-trademark-registered", modifier, size, style)
@Composable fun PhTrafficCone(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-traffic-cone", modifier, size, style)
@Composable fun PhTrafficSign(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-traffic-sign", modifier, size, style)
@Composable fun PhTrafficSignal(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-traffic-signal", modifier, size, style)
@Composable fun PhTrain(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-train", modifier, size, style)
@Composable fun PhTrainRegional(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-train-regional", modifier, size, style)
@Composable fun PhTrainSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-train-simple", modifier, size, style)
@Composable fun PhTram(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tram", modifier, size, style)
@Composable fun PhTranslate(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-translate", modifier, size, style)
@Composable fun PhTrash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-trash", modifier, size, style)
@Composable fun PhTrashSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-trash-simple", modifier, size, style)
@Composable fun PhTray(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tray", modifier, size, style)
@Composable fun PhTree(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tree", modifier, size, style)
@Composable fun PhTreeEvergreen(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tree-evergreen", modifier, size, style)
@Composable fun PhTreePalm(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tree-palm", modifier, size, style)
@Composable fun PhTreeStructure(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-tree-structure", modifier, size, style)
@Composable fun PhTrendDown(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-trend-down", modifier, size, style)
@Composable fun PhTrendUp(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-trend-up", modifier, size, style)
@Composable fun PhTriangle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-triangle", modifier, size, style)
@Composable fun PhTrophy(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-trophy", modifier, size, style)
@Composable fun PhTruck(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-truck", modifier, size, style)
@Composable fun PhTwitchLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-twitch-logo", modifier, size, style)
@Composable fun PhTwitterLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-twitter-logo", modifier, size, style)
@Composable fun PhUmbrella(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-umbrella", modifier, size, style)
@Composable fun PhUmbrellaSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-umbrella-simple", modifier, size, style)
@Composable fun PhUnite(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-unite", modifier, size, style)
@Composable fun PhUniteSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-unite-square", modifier, size, style)
@Composable fun PhUpload(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-upload", modifier, size, style)
@Composable fun PhUploadSimple(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-upload-simple", modifier, size, style)
@Composable fun PhUsb(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-usb", modifier, size, style)
@Composable fun PhUser(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-user", modifier, size, style)
@Composable fun PhUserCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-user-circle", modifier, size, style)
@Composable fun PhUserCircleGear(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-user-circle-gear", modifier, size, style)
@Composable fun PhUserCircleMinus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-user-circle-minus", modifier, size, style)
@Composable fun PhUserCirclePlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-user-circle-plus", modifier, size, style)
@Composable fun PhUserFocus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-user-focus", modifier, size, style)
@Composable fun PhUserGear(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-user-gear", modifier, size, style)
@Composable fun PhUserList(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-user-list", modifier, size, style)
@Composable fun PhUserMinus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-user-minus", modifier, size, style)
@Composable fun PhUserPlus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-user-plus", modifier, size, style)
@Composable fun PhUserRectangle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-user-rectangle", modifier, size, style)
@Composable fun PhUserSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-user-square", modifier, size, style)
@Composable fun PhUserSwitch(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-user-switch", modifier, size, style)
@Composable fun PhUsers(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-users", modifier, size, style)
@Composable fun PhUsersFour(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-users-four", modifier, size, style)
@Composable fun PhUsersThree(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-users-three", modifier, size, style)
@Composable fun PhVan(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-van", modifier, size, style)
@Composable fun PhVault(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-vault", modifier, size, style)
@Composable fun PhVibrate(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-vibrate", modifier, size, style)
@Composable fun PhVideo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-video", modifier, size, style)
@Composable fun PhVideoCamera(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-video-camera", modifier, size, style)
@Composable fun PhVideoCameraSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-video-camera-slash", modifier, size, style)
@Composable fun PhVignette(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-vignette", modifier, size, style)
@Composable fun PhVinylRecord(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-vinyl-record", modifier, size, style)
@Composable fun PhVirtualReality(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-virtual-reality", modifier, size, style)
@Composable fun PhVirus(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-virus", modifier, size, style)
@Composable fun PhVoicemail(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-voicemail", modifier, size, style)
@Composable fun PhVolleyball(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-volleyball", modifier, size, style)
@Composable fun PhWall(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wall", modifier, size, style)
@Composable fun PhWallet(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wallet", modifier, size, style)
@Composable fun PhWarehouse(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-warehouse", modifier, size, style)
@Composable fun PhWarning(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-warning", modifier, size, style)
@Composable fun PhWarningCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-warning-circle", modifier, size, style)
@Composable fun PhWarningDiamond(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-warning-diamond", modifier, size, style)
@Composable fun PhWarningOctagon(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-warning-octagon", modifier, size, style)
@Composable fun PhWatch(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-watch", modifier, size, style)
@Composable fun PhWaveSawtooth(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wave-sawtooth", modifier, size, style)
@Composable fun PhWaveSine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wave-sine", modifier, size, style)
@Composable fun PhWaveSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wave-square", modifier, size, style)
@Composable fun PhWaveTriangle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wave-triangle", modifier, size, style)
@Composable fun PhWaveform(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-waveform", modifier, size, style)
@Composable fun PhWaves(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-waves", modifier, size, style)
@Composable fun PhWebcam(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-webcam", modifier, size, style)
@Composable fun PhWebcamSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-webcam-slash", modifier, size, style)
@Composable fun PhWebhooksLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-webhooks-logo", modifier, size, style)
@Composable fun PhWechatLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wechat-logo", modifier, size, style)
@Composable fun PhWhatsappLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-whatsapp-logo", modifier, size, style)
@Composable fun PhWheelchair(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wheelchair", modifier, size, style)
@Composable fun PhWheelchairMotion(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wheelchair-motion", modifier, size, style)
@Composable fun PhWifiHigh(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wifi-high", modifier, size, style)
@Composable fun PhWifiLow(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wifi-low", modifier, size, style)
@Composable fun PhWifiMedium(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wifi-medium", modifier, size, style)
@Composable fun PhWifiNone(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wifi-none", modifier, size, style)
@Composable fun PhWifiSlash(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wifi-slash", modifier, size, style)
@Composable fun PhWifiX(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wifi-x", modifier, size, style)
@Composable fun PhWind(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wind", modifier, size, style)
@Composable fun PhWindowsLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-windows-logo", modifier, size, style)
@Composable fun PhWine(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wine", modifier, size, style)
@Composable fun PhWrench(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-wrench", modifier, size, style)
@Composable fun PhX(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-x", modifier, size, style)
@Composable fun PhXCircle(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-x-circle", modifier, size, style)
@Composable fun PhXSquare(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-x-square", modifier, size, style)
@Composable fun PhYinYang(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-yin-yang", modifier, size, style)
@Composable fun PhYoutubeLogo(modifier: Modifier = Modifier, size: CSSNumeric = LyricalTheme.Size.Icon.Default, style: IconStyle = IconStyle.BOLD) = PhIcon("ph-youtube-logo", modifier, size, style)